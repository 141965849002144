import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"

import { color } from "../../utils/style-components"

const Sent: React.FC = () => (
  <div>
    <h3>送信が完了しました</h3>
    <p
      css={css`
        padding-top: min(50px, 8vw);
      `}
    >
      ご応募いただきありがとうございました。
      <br />
      ご提出いただいた書類により書類審査を行います。
      <br />
      審査の結果は担当者より10日以内にご連絡させていただきますので、恐れ入りますがしばらくお待ちください。
      <br />
      <br />
      なお、ご入力いただいたメールアドレス宛に受付完了メールを配信しております。
      <br />
      完了メールが届かない場合、処理が正常に行われていない可能性がございます。
      <br />
      その際は大変お手数ですが、再度お問い合わせをお願い致します。
    </p>
    <div
      css={css`
        display: flex;
        justify-content: center;
        margin-top: min(100px, 11vw);
      `}
    >
      <Link
        to="/"
        state={{ skipMovie: true }}
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          font-weight: bold;
          font-size: 1.6rem;
          line-height: 1;
          color: ${color.white};
          background: ${color.blue};
          width: 270px;
          height: 70px;
        `}
      >
        TOPに戻る
      </Link>
    </div>
  </div>
)

export default Sent
