import React, { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { PageProps } from "gatsby"
import styled from "@emotion/styled"
import { scroller, Element } from "react-scroll"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  RecruitWrap,
  RecruitInner,
  RecruitTitle,
  RecruitHeader,
} from "../../components/recruit/recruit-parts"
import RecruitForm from "../../components/recruit/recruit-form"
import Confirm from "../../components/recruit/confirm"
import Sending from "../../components/contact/sending"
import Sent from "../../components/recruit/sent"

import RecruitSwitcher from "../../components/recruit/recruit-switcher"

type FormLocation = {
  jobType?: string
}
interface IFormValues {
  name: string
  kana: string
  birthy: number
  birthm: number
  birthd: number
  gender: string
  email: string
  tel: string
  postnumber: string
  address: string
  period: string
  jobtype: string
  message: string
  resume: FileList
  cv: FileList
  agreement: boolean
  [key: string]: string | boolean | number | FileList
}

type PageStateType = "form" | "confirm" | "sending" | "sent"

const Form: React.FC<PageProps<null, null, FormLocation>> = ({ location }) => {
  const methods = useForm<IFormValues>()
  const { handleSubmit, reset } = methods

  const defaultJobType = location?.state?.jobType || `career`

  const [pageState, setPageState] = useState<PageStateType>("form")
  const [isSendError, setIsSendError] = useState(false)

  const scrollToFormTop = () => {
    scroller.scrollTo(`formTop`, {
      duration: 800,
      delay: 0,
      offset: -300,
      smooth: `easeOutQuint`,
    })
  }

  const apiURL = process.env.GATSBY_API_URL || ""
  const sendFormData = (data: IFormValues) => {
    const formData = new FormData()
    for (const key in data) {
      if (key === "resume" || key === "cv") {
        formData.append(key, data[key][0])
      } else {
        formData.append(key, data[key] + "")
      }
    }

    fetch(`${apiURL}/api/recruit/`, {
      method: "POST",
      body: formData,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response is not ok.`)
        }
        return response.json()
      })
      .then(data => {
        if (process.env.GATSBY_ENV === `develop`) {
          console.log(data)
        }
        if (!data.mailStatus.status) {
          throw new Error(`Message send failure.`)
        }
        setPageState("sent")
        reset()
        scrollToFormTop()
      })
      .catch(error => {
        if (process.env.GATSBY_ENV === `develop`) {
          console.log(
            "There has been a problem with your send operation:",
            error
          )
        }
        setIsSendError(true)
        setPageState("form")
        scrollToFormTop()
      })
  }

  const onSubmit = (data: IFormValues) => {
    switch (pageState) {
      case "form":
        setIsSendError(false)
        setPageState("confirm")
        scrollToFormTop()
        break
      case "confirm":
        setPageState("sending")
        scrollToFormTop()

        sendFormData(data)

        break
    }
  }

  const backToForm = () => {
    setPageState("form")
    scrollToFormTop()
  }

  return (
    <Layout>
      <SEO
        title="Recruit　求人募集"
        description="東京都八丈島への移住・定住をご希望の方、Ｕターンの方も大歓迎です。"
      />
      <RecruitSwitcher>
        <RecruitWrap>
          <RecruitInner>
            <RecruitTitle>Recruit</RecruitTitle>
            <RecruitHeader>
              {pageState === "form" && "応募フォーム"}
              {pageState === "confirm" && "応募内容の確認"}
              {pageState === "sending" && "送信中"}
              {pageState === "sent" && "応募完了"}
            </RecruitHeader>
            <Element name="formTop" />
            {pageState === "sent" && <Sent />}
            {pageState === "sending" && <Sending />}
            <FormWrap>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {pageState === "form" && (
                    <RecruitForm
                      isSendError={isSendError}
                      defaultJobType={defaultJobType}
                    />
                  )}
                  {pageState === "confirm" && (
                    <Confirm backToForm={backToForm} />
                  )}
                </form>
              </FormProvider>
            </FormWrap>
          </RecruitInner>
        </RecruitWrap>
      </RecruitSwitcher>
    </Layout>
  )
}

const FormWrap = styled.div`
  margin-top: 80px;
`

export default Form
